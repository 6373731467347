import { graphql } from 'gatsby'
import React from 'react'
import "twin.macro"
import { Link } from "gatsby"
import Imgix from "react-imgix"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentGrid from '../components/contentGrid'

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMicrocmsNews(
      sort: {fields: publishedAt, order: DESC}
      limit: $limit,
      skip: $skip
      ) {
      edges {
        node {
          title
          newsId
          main_img {
            url
          }
          publishedAt(formatString: "YYYY.MM.DD")
          event_day
        }
      }
    }
  }
`

const NewsPage = ({ data, location, pageContext }) => {
  return (
    <Layout workPage={true}>
      <Seo 
        title="News" 
        description="お知らせのアーカイブページです。"
        workPage={true} 
        pagePath={location.pathname}
      />
      <ContentGrid>
        <article className="work_content mt-40">
          <section>
            <h2 tw="font-en text-gold text-5xl md:text-7xl tracking-wider">NEWS</h2>
            <div>
              {data.allMicrocmsNews.edges.map(({ node }) => (
                <article key={node.newsId} tw="mt-20">
                  <Link 
                      to={`/news/${node.newsId}/`}
                      title={ node.title }
                      tw="block relative w-full"
                      className='group'
                    >
                    <div tw="w-full h-40 md:h-60 overflow-hidden rounded-md relative">
                      <span
                        tw="
                          transition-colors
                          absolute 
                          top-0 
                          left-0 
                          w-full 
                          h-full 
                          bg-black
                          bg-opacity-50
                          group-hover:bg-opacity-0
                        "
                      ></span>
                      <figure tw="w-full h-full">
                        <Imgix
                          src={node.main_img.url}
                          sizes="(max-width: 800px) 100vw, 400px"
                          htmlAttributes={{
                            alt: `${node.title}`,
                          }}
                          tw="w-full h-full object-cover"
                        />
                      </figure>
                    </div>
                    <div tw="font-en text-gold tracking-wider">POSTED: { node.publishedAt }</div>
                    <h2 tw="text-sm tracking-wider">{ node.title }</h2>
                    
                    {node.event_day &&(
                      <h3 tw="text-sm font-normal opacity-50 tracking-wider">EVENT DATE: { node.event_day }</h3>
                    )}
                    
                  </Link>
                </article>
              ))}
            </div>
          </section>
          <ul tw="mt-20 grid grid-cols-2 gap-2 md:gap-4">
            {!pageContext.isFirst && (
              <li>
                <Link
                  to={
                    pageContext.currentPage === 2
                      ? `/news/`
                      : `/news/${pageContext.currentPage - 1}`
                  }
                  rel="prev"
                  className='group'
                  tw="block w-full bg-gold bg-opacity-0 hover:bg-opacity-100 border transition border-gold mt-2 py-2 px-2 rounded-md font-en"
                >
                  <span tw="transition-all tracking-wider block text-center items-center text-gold group-hover:text-white">PREV</span>
                </Link>
              </li>
            )}
            {!pageContext.isLast && (
              <li>
                <Link
                  to={
                    `/news/${pageContext.currentPage + 1}/`
                  }
                  rel="next"
                  className='group'
                  tw="block w-full bg-gold bg-opacity-0 hover:bg-opacity-100 border transition border-gold mt-2 py-2 px-2 rounded-md font-en"
                >
                  <span tw="transition-all tracking-wider block text-center items-center text-gold group-hover:text-white">NEXT</span>
                </Link>
              </li>
            )}
          </ul>
          <footer tw="py-10 pb-20">
            <small tw="font-en text-gold text-lg tracking-wider">© Yumiko Kinjo</small>
          </footer>
        </article>
      </ContentGrid>
    </Layout>
  )
}
  
export default NewsPage